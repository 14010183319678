import baseComponent from "@/scripts/baseComponent"; 
import { mapState } from "vuex";
import EventBus from "@/event/EventBus"; 
const styleDisabled = 'pointer-events: none; filter: grayscale(100%); opacity: 0.25;';
const styleEnabled = 'cursor: pointer;';

export default {

    extends: baseComponent,

    data() {
        return {
            componentName: null,
            componentTitle: null,
            isSystemReportedEnabled: false,
            isPackageSettingEnabled: false,
            isMachineSettingEnabled: false,
            isPaymentSettingEnabled: false,
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
            systemManagerInformation: state => state.auth.systemManagerInformation,
        }),
        componentFile() {
            if (this.componentName) {
                switch (this.componentName) {
                    case 'StaffManagement':
                        this.componentTitle = 'Staff management'
                        break;
                    case 'RoleManagement':
                        this.componentTitle = 'Role management'
                        break;
                    case 'RestrictedEmailDomain':
                        this.componentTitle = 'Restricted email domain'
                        break;
                    case 'RestrictedSoftware':
                        this.componentTitle = 'Restricted software'
                        break;
                    case 'RestrictedIp':
                        this.componentTitle = 'Restricted IP'
                        break;
                    case 'ExcludedIp':
                        this.componentTitle = 'Excluded IP'
                        break;
                    case 'AdminFunctions':
                        this.componentTitle = 'Admin functions'
                        break;
                    case 'RestrictedRule':
                        this.componentTitle = 'Restricted rule'
                        break;
                }
                return () => import(`@/views/adminManager/systemManager/${this.componentName}.vue`);
            } else {
                return null
            }
        },
        styleDisabled() { return styleDisabled; },
        styleEnabled() { return styleEnabled; },
    },

    created() {

    },

    mounted() {

    },

    methods: {

        openPopup(command) {
            EventBus.$emit("openPopup", command);
        },

        // permission(param) {
        //     this.decentralization.filter(
        //         (x) =>
        //             x.functionId === param
        //     ).length > 0
        // }
    }
}